import React, { useState, useEffect, useRef } from "react";
import "./OutrightsPopup.scss";
import closeIcon from "assets/icons/close-icon.svg";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

const OutrightsPopup = ({ setOutright, outright }) => {
  const { t } = useTranslation();
  const { chalangeId, isLoadingOutrights } = useStoreState((store) => store.game);
  const { outrightsOdds } = useStoreState((store) => store.matches);
  const { getOutrightsOdds, postOutrightBet, patchOutrightBet } = useStoreActions(
    (actions) => actions.matches
  );
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    if (outright && chalangeId) {
      getOutrightsOdds({ challengeId: chalangeId, oddTypeId: outright?.oddTypeId });
    }
  }, [outright, chalangeId]);

  useEffect(() => {
    if (outrightsOdds?.odds?.[0]?.oddOptions?.length && outright?.userBets) {
      setSelectedValue(
        outrightsOdds?.odds?.[0]?.oddOptions?.find(
          (item) => item?._id === outright?.userBets?.[0]?.oddOptionsId
        )
      );
    }
  }, [outrightsOdds]);

  const applyTeamRules = (teamName) => {
    switch (teamName.toLowerCase()) {
      case "arsenal":
        return "Arsenal";
      case "ac milan":
        return "AC Milan";
      case "as monaco":
        return "AS Monaco";
      case "aston villa":
        return "Aston Villa";
      case "atalanta bergamo":
        return "Atalanta Bergamo";
      case "atletico madrid":
        return "Atlético Madrid";
      case "bayer 04 leverkusen":
        return "Bayer 04 Leverkusen";
      case "bologna f.c.":
        return "Bologna FC";
      case "borussia dortmund":
        return "Borussia Dortmund";
      case "bsc young boys bern":
        return "Young Boys Bern";
      case "celtic fc":
        return "Celtic Glasgow";
      case "club brugge kv":
        return "Club Brugge";
      case "gnk dinamo zagreb":
        return "Dinamo Zagreb";
      case "fc barcelona":
        return "FC Barcelona";
      case "fc bayern munich":
        return "FC Bayern Munich";
      case "feyenoord rotterdam":
        return "Feyenoord Rotterdam";
      case "girona fc":
        return "Girona FC";
      case "internazionale milano":
        return "Inter Milan";
      case "juventus fc":
        return "Juventus Turin";
      case "osc lille":
        return "Lille OSC";
      case "liverpool":
        return "Liverpool FC";
      case "manchester city":
        return "Manchester City";
      case "paris saint-germain fc":
        return "Paris Saint-Germain";
      case "psv eindhoven":
        return "PSV Eindhoven";
      case "rb leipzig":
        return "RB Leipzig";
      case "fc red bull salzburg":
        return "RB Salzburg";
      case "real madrid":
        return "Real Madrid";
      case "fk red star belgrade (crvena zvezda)":
        return "Red Star Belgrade";
      case "fc shakhtar donetsk":
        return "Schachtar Donezk";
      case "sl benfica":
        return "SL Benfica";
      case "sk slovan bratislava":
        return "Slovan Bratislava";
      case "sporting clube de portugal":
        return "Sporting CP";
      case "stade brestois 29":
        return "Stade Brestois 29";
      case "sk sturm graz":
        return "Sturm Graz";
      case "ac sparta praha":
        return "Sparta Prague";
      case "vfb stuttgart":
        return "VfB Stuttgart";
      default:
        return teamName;
    }
  };

  const allowedTeams = [
    "arsenal",
    "ac milan",
    "as monaco",
    "aston villa",
    "atalanta bergamo",
    "atletico madrid",
    "bayer 04 leverkusen",
    "bologna f.c.",
    "borussia dortmund",
    "bsc young boys bern",
    "celtic fc",
    "club brugge kv",
    "gnk dinamo zagreb",
    "fc barcelona",
    "fc bayern munich",
    "feyenoord rotterdam",
    "girona fc",
    "internazionale milano",
    "juventus fc",
    "osc lille",
    "liverpool",
    "manchester city",
    "paris saint-germain fc",
    "psv eindhoven",
    "rb leipzig",
    "fc red bull salzburg",
    "real madrid",
    "fk red star belgrade (crvena zvezda)",
    "fc shakhtar donetsk",
    "sl benfica",
    "sk slovan bratislava",
    "sporting clube de portugal",
    "stade brestois 29",
    "sk sturm graz",
    "ac sparta praha",
    "vfb stuttgart"
  ];
console.log(outrightsOdds, "outrightsOdds")
  const filteredAndSortedTeams = outrightsOdds?.odds?.[0]?.oddOptions
  ?.filter((team) => allowedTeams.includes(team.name.toLowerCase()))
  ?.sort((a, b) => applyTeamRules(a.name).localeCompare(applyTeamRules(b.name)));

  const listenForOutsideClicks = (listening, setListening, menuRef) => {
    return () => {
      [`click`, `touchstart`].forEach(() => {
        document.addEventListener(`click`, (evt) => {
          if (!listening) {
            setListening(true);
            return;
          } else {
            const cur = menuRef?.current;
            const node = evt?.target;

            if (cur && !cur.contains(node)) {
              setOutright(null);
            }
          }
        });
      });
    };
  };

  useEffect(() => {
    listenForOutsideClicks(listening, setListening, menuRef);
  }, [listening]);

  const oddsHandler = () => {
    if (outright?.userBets) {
      const body = {
        oddOptionsId: [selectedValue?._id],
      };
      patchOutrightBet({
        challengeId: chalangeId,
        competitionId: process.env.REACT_APP_COMPETITION_ID,
        oddTypeId: outright?.userBets?.[0]?.odds?.oddTypeId,
        body,
      });
      setSelectedValue(null);
      setOutright(null);
    } else {
      postOutrightBet({
        challengeId: chalangeId,
        competitionId: process.env.REACT_APP_COMPETITION_ID,
        oddOptionsId: [selectedValue?._id],
      });
      setSelectedValue(null);
      setOutright(null);
    }
  };

  return (
    <div className="pop-up-vote-container">
      <div className="pop-up-container--inner" ref={menuRef}>
        <div className="btn-close--container">
          <button className="btn-close" onClick={() => setOutright(null)}>
            <img src={closeIcon} alt="close" />
          </button>
        </div>

        <div className="select-player">
          <h3 className="title">{t("votes.select_team")}</h3>

          {isLoadingOutrights ? (
            <div className="loading-spinner"></div>
          ) : (
            <div className="options">
              {filteredAndSortedTeams?.map((item, index) => (
                <div
                  key={index}
                  className={classNames("option", {
                    selected: selectedValue?._id === item?._id,
                  })}
                  onClick={() => setSelectedValue(item)}
                >
                  <div className="team-container">
                    <p className="name">{applyTeamRules(t(item?.name))}</p>
                  </div>
                  <p className="value">
                    {item?.value} {t("matches.pts")}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <button className="btn" onClick={oddsHandler}>
          {!outright?.userBets ? t("matches.place_prediction") : t("matches.change_prediction")}
        </button>
      </div>
    </div>
  );
};

export default OutrightsPopup;
