import navigationModel from "./navigationModel";
import userModel from "./userModel";
import matchesModel from "./matchesModel";
import leaderboardsModel from "./leaderboardsModel";
import gameModel from "./gameModel";
import votePlayerModel from "./votePlayerModel";
import chatModel from "./chatModel";

const model = {
  user: userModel,
  game: gameModel,
  navigation: navigationModel,
  matches: matchesModel,
  leaderboards: leaderboardsModel,
  votePlayer: votePlayerModel,
  chat: chatModel,
};

export default model;
