import React, { useEffect, useState } from "react";

import styles from "./Outrights.module.scss";
import { useTranslation } from "react-i18next";
import { useStoreState } from "easy-peasy";
import changeIcon from "assets/icons/change-icon.svg";
import plusIcon from "assets/plus-icon.png";
import OutrightsPopup from "components/OutrightsPopup/OutrightsPopup";
import { LoaderWithLogo } from "components";

const Outrights = () => {
  const { t } = useTranslation();
  const { outrights, isLoadingOutrights } = useStoreState((store) => store.matches);
  const [selectedOutright, setSelectedOutright] = useState();

  useEffect(() => {
    if (selectedOutright) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [selectedOutright]);

  return isLoadingOutrights ? (
    <div className={styles.outrights_section}>
      <div className="container">
        <div className={styles.outrights}>
          <LoaderWithLogo />
        </div>
      </div>
    </div>
  ) : (
    outrights?.map((item, index) => {
      return (
        !(new Date(item?.finalBettingDate) < new Date() && !item?.userBets) && (
          <div className={styles.outrights_section}>
            <div className="container">
              <div className={styles.outrights}>
                {isLoadingOutrights ? (
                  <LoaderWithLogo />
                ) : (
                  outrights?.map((item, index) => {
                    return (
                      <div key={index} className={styles.outright}>
                        <h3>{t(`${item?.oddType?.name}_title`)}</h3>

                        {<p>{t(`${item?.oddType?.name}_description`)}</p>}

                        {(item?.userBets?.[0]?.team?.emblemUrl ||
                          item?.userBets?.[0]?.oddOption?.name) && (
                          <div className={styles.teamContainer}>
                            {item?.userBets?.[0]?.team?.emblemUrl && (
                              <div className={styles.teamLogo}>
                                <img
                                  src={item?.userBets?.[0]?.team?.emblemUrl}
                                  alt={item?.userBets?.[0]?.oddOption?.name}
                                />
                              </div>
                            )}
                            {item?.userBets?.[0]?.oddOption?.name && (
                              <span className={styles.teamName}>
                                {t(item?.userBets?.[0]?.oddOption?.name)}
                              </span>
                            )}
                          </div>
                        )}
                        {new Date(item?.finalBettingDate) > new Date() ? (
                          <button
                            className={`${styles.btn} ${styles.fullWidth} ${styles.roundCorner} ${new Date(item?.finalBettingDate) < new Date() ? styles.disabled : ""}`}
                            onClick={() => setSelectedOutright(item)}
                            disabled={new Date(item?.finalBettingDate) < new Date()}
                          >
                            <span className={styles.icon}>
                              {item?.userBets ? (
                                <img src={changeIcon} alt="<->" />
                              ) : (
                                <img src={plusIcon} alt="+" />
                              )}
                            </span>
                            <span className={styles.text}>
                              {!item?.userBets
                                ? t("matches.place_prediction")
                                : t("matches.change_prediction")}
                            </span>
                          </button>
                        ) : (
                          <span
                            className={styles.points}
                          >{`${item?.userBets[0]?.selectedPoints} ${t("matches.pts")}`}</span>
                        )}
                      </div>
                    );
                  })
                )}
              </div>
            </div>
            {selectedOutright && (
              <OutrightsPopup outright={selectedOutright} setOutright={setSelectedOutright} />
            )}
          </div>
        )
      );
    })
  );
};

export default Outrights;
