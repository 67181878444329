import { CorrectScore, NoOdds, OddMultipleOptions, OddSelector } from "components";
import React from "react";

const DynamicOdds = ({ odds, betTemplate, team_one, team_two, userBets }) => {
  const PrintDynamicOdd = ({ oddTypeSelection }) => {
    const odd = odds?.find((odd) => odd.order === oddTypeSelection.order);
    switch (true) {
      case odd?.oddType?.externalBettingTypeId === 45:
        return (
          <CorrectScore odd={odd} team_one={team_one} team_two={team_two} userBets={userBets} />
        );
      case odd?.oddOptions?.length > 1 && odd?.oddOptions?.length < 4:
        return (
          <OddSelector odd={odd} team_one={team_one} team_two={team_two} userBets={userBets} />
        );
      case odd?.oddOptions?.length > 3 && odd?.oddType?.externalBettingTypeId !== 45:
        return (
          <OddMultipleOptions
            odd={odd}
            team_one={team_one}
            team_two={team_two}
            userBets={userBets}
          />
        );
      case !odd:
        return (
          <NoOdds
            name={`${oddTypeSelection?.bettingTypeName}, ${oddTypeSelection?.eventPartName}`}
          />
        );
      default:
        return null;
    }
  };

  const sortedOddTypeSelection = betTemplate?.oddTypeSelection?.sort((a, b) => {
    const oddA = odds?.find((odd) => odd.order === a.order);
    const oddB = odds?.find((odd) => odd.order === b.order);
    if (oddA?.oddType?.externalBettingTypeId === 45) return -1;
    if (oddB?.oddType?.externalBettingTypeId === 45) return 1;
    return 0;
  });

  return (
    <>
      {sortedOddTypeSelection?.map((oddTypeSelection, index) => {
        return <PrintDynamicOdd key={index} oddTypeSelection={oddTypeSelection} />;
      })}
    </>
  );
};

export default React.memo(DynamicOdds);
