import React, { useEffect } from "react";

import styles from "./OddSelector.module.scss";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "easy-peasy";
import classNames from "classnames";

const OddSelector = ({ odd, team_one, team_two, userBets }) => {
  const { t } = useTranslation();
  const { setBets } = useStoreActions((actions) => actions.matches);
  const { bets, correctScoreWinner } = useStoreState((actions) => actions.matches);

  const oddHandler = (item) => {
    const payload = {
      gameId: odd?.gameId,
      oddId: odd?._id,
      oddOptionId: item?._id,
    };
    setBets(payload);
  };

  useEffect(() => {
    if (odd?.name === "Home Draw Away, Ordinary Time") {
      switch (correctScoreWinner) {
        case "TeamOne":
          oddHandler(odd?.oddOptions[0]);
          break;
        case "Draw":
          oddHandler(odd?.oddOptions[1]);
          break;
        case "TeamTwo":
          oddHandler(odd?.oddOptions[2]);
          break;
        default:
          break;
      }
    }
  }, [correctScoreWinner]);

  return (
    <div className={styles.odd_selector}>
      <h2>{t(odd?.name)}</h2>
      <div className={styles.odd_options}>
        {odd?.oddOptions.map((item, index) => {
          return (
            <div className={styles.odd_option} key={index}>
              <button
                className={classNames(styles.logo, {
                  [styles.active]: bets?.[odd?.gameId]?.[odd?._id] === item?._id,
                })}
                onClick={() => oddHandler(item)}
                disabled={!item.value}
              >
                <p>{t(item?.name)}</p>
              </button>
              <p className={styles.value}>
                {item.value && (
                  <>
                    {item.value.toFixed(2)} {t("matches.pts")}
                  </>
                )}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OddSelector;
