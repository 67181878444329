import React, { useState, useEffect, useRef } from "react";
import "./TeamsPopup.scss";
import closeIcon from "../../assets/icons/close-icon.svg";
import { useTranslation } from "react-i18next";

const TeamsPopup = ({ setSelectedTeam, setOpenInfoPopUp, title, data, setPlayersPopUpIsOpen }) => {
  const { t } = useTranslation();
  const menuRef = useRef(null);
  const [listening, setListening] = useState(false);

  const capitalizeWords = (str) => {
    return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  };
console.log(data, "data")
  const applyTeamRules = (teamName) => {
    switch (teamName.toLowerCase()) {
      case "arsenal":
        return "Arsenal";
      case "ac milan":
        return "AC Milan";
      case "as monaco":
        return "AS Monaco";
      case "aston villa":
        return "Aston Villa";
      case "atalanta bergamo":
        return "Atalanta Bergamo";
      case "atletico madrid":
        return "Atlético Madrid";
      case "bayer 04 leverkusen":
        return "Bayer 04 Leverkusen";
      case "bologna f.c.":
        return "Bologna FC";
      case "borussia dortmund":
        return "Borussia Dortmund";
      case "bsc young boys bern":
        return "Young Boys Bern";
      case "celtic fc":
        return "Celtic Glasgow";
      case "club brugge kv":
        return "Club Brugge";
      case "gnk dinamo zagreb":
        return "Dinamo Zagreb";
      case "fc barcelona":
        return "FC Barcelona";
      case "fc bayern munich":
        return "FC Bayern Munich";
      case "feyenoord rotterdam":
        return "Feyenoord Rotterdam";
      case "girona fc":
        return "Girona FC";
      case "internazionale milano":
        return "Inter Milan";
      case "juventus fc":
        return "Juventus Turin";
      case "osc lille":
        return "Lille OSC";
      case "liverpool":
        return "Liverpool FC";
      case "manchester city":
        return "Manchester City";
      case "paris saint-germain fc":
        return "Paris Saint-Germain";
      case "psv eindhoven":
        return "PSV Eindhoven";
      case "rb leipzig":
        return "RB Leipzig";
      case "fc red bull salzburg":
        return "RB Salzburg";
      case "real madrid":
        return "Real Madrid";
      case "fk red star belgrade (crvena zvezda)":
        return "Red Star Belgrade";
      case "fc shakhtar donetsk":
        return "Schachtar Donezk";
      case "sl benfica":
        return "SL Benfica";
      case "sk slovan bratislava":
        return "Slovan Bratislava";
      case "sporting clube de portugal":
        return "Sporting CP";
      case "stade brestois 29":
        return "Stade Brestois 29";
      case "sk sturm graz":
        return "Sturm Graz";
      case "ac sparta praha":
        return "Sparta Prague";
      case "vfb stuttgart":
        return "VfB Stuttgart";
      default:
        return null;
    }
  };


  const allowedTeams = [
    "arsenal",
    "ac milan",
    "as monaco",
    "aston villa",
    "atalanta bergamo",
    "atletico madrid",
    "bayer 04 leverkusen",
    "bologna f.c.",
    "borussia dortmund",
    "bsc young boys bern",
    "celtic fc",
    "club brugge kv",
    "gnk dinamo zagreb",
    "fc barcelona",
    "fc bayern munich",
    "feyenoord rotterdam",
    "girona fc",
    "internazionale milano",
    "juventus fc",
    "osc lille",
    "liverpool",
    "manchester city",
    "paris saint-germain fc",
    "psv eindhoven",
    "rb leipzig",
    "fc red bull salzburg",
    "real madrid",
    "fk red star belgrade (crvena zvezda)",
    "fc shakhtar donetsk",
    "sl benfica",
    "sk slovan bratislava",
    "sporting clube de portugal",
    "stade brestois 29",
    "sk sturm graz",
    "ac sparta praha",
    "vfb stuttgart"
  ];


  const filteredData = data.filter((team) =>
    allowedTeams.includes(team.name.toLowerCase())
  );

  const sortedData = [...filteredData].sort((a, b) => {
    const teamA = applyTeamRules(a.name).toLowerCase();
    const teamB = applyTeamRules(b.name).toLowerCase();
    return teamA.localeCompare(teamB);
  });


  const listenForOutsideClicks = (listening, setListening, menuRef) => {
    return () => {
      [`click`, `touchstart`].forEach(() => {
        document.addEventListener(`click`, (evt) => {
          if (!listening) {
            setListening(true);
            return;
          } else {
            const cur = menuRef?.current;
            const node = evt?.target;

            if (cur && !cur.contains(node)) {
              setOpenInfoPopUp(false);
            }
          }
        });
      });
    };
  };

  useEffect(() => {
    listenForOutsideClicks(listening, setListening, menuRef);
  }, [listening]);

  const handleTeam = (team) => {
    setSelectedTeam(team);
    setOpenInfoPopUp(false);
    if (setPlayersPopUpIsOpen) {
      setPlayersPopUpIsOpen(true);
    }
  };

  return (
    <div className="pop-up-info-container">
      <div className="pop-up-container--inner" ref={menuRef}>
        <div className="btn-close--container">
          <button
            className="btn-close"
            onClick={() => {
              setOpenInfoPopUp(false);
            }}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>

        <h2 className="terms-title">{title}</h2>
        <div className="teams_container">
          {sortedData.map((item, index) => {
            return (
              <div className="team_container" onClick={() => handleTeam(item)} key={index}>
                <div className="team_title">
                  <p>{applyTeamRules(t(item.name))}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default TeamsPopup;
