import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api from "../../api/axios";
import { urlParamReplacer, CHAT } from "./apiRoutes";

const chatModel = {
  // DATA
  favoriteTeamChatId: null,
  favoriteTeamChat: null,
  globalChatId: null,
  messages: [],
  hasMoreMessages: true,
  profanityWords: [],
  lastConversations: {},

  // ACTIONS
  setFavoriteTeamChatId: action((state, favoriteTeamChatId) => {
    state.favoriteTeamChatId = favoriteTeamChatId;
  }),
  setFavoriteTeamChat: action((state, favoriteTeamChat) => {
    state.favoriteTeamChat = favoriteTeamChat;
  }),
  setGlobalChatId: action((state, globalChatId) => {
    state.globalChatId = globalChatId;
  }),
  setProfanityWords: action((state, profanityWords) => {
    state.profanityWords = profanityWords;
  }),
  setLastConversations: action((state, { conversationId, data }) => {
    state.lastConversations[conversationId] = data;
  }),
  setMessages: action((state, payload) => {
    const { messages, offset } = payload;
    if (messages?.length % 20 !== 0 || messages?.length === 0) {
      state.hasMoreMessages = false;
    } else {
      state.hasMoreMessages = true;
    }
    if (state.messages?.length > 0 && offset > 0) {
      state.messages = [...messages.reverse(), ...state.messages];
    } else {
      state.messages = [...messages].reverse();
    }
  }),

  // THUNKS
  getFavoriteTeamChat: thunk(async (actions, payload) => {
    const teamId = payload;
    try {
      const { data } = await api.get(urlParamReplacer(CHAT.GROUPS, teamId));
      actions.setFavoriteTeamChatId(data[0]?.conversationId);
      actions.setFavoriteTeamChat(data[0]);
    } catch (error) {
      console.log(error);
    }
  }),
  getGlobalChatId: thunk(async (actions, payload) => {
    const { challengeId } = payload;
    try {
      const { data } = await api.get(urlParamReplacer(CHAT.CHALLENGE, challengeId));
      actions.setGlobalChatId(data?.conversationId);
    } catch (error) {
      console.log(error);
    }
  }),
  getProfanityWords: thunk(async (actions) => {
    try {
      const { data } = await api.get(CHAT.PROFANITY);
      actions.setProfanityWords(data[0]?.value?.profanityWords);
    } catch (error) {
      console.log(error);
    }
  }),
  getLastConversations: thunk(async (actions, payload) => {
    const { conversationId } = payload;
    try {
      const { data } = await api.get(urlParamReplacer(CHAT.CONVERSATIONS, conversationId));
      actions.setLastConversations({ data: data?.lastMessage, conversationId });
    } catch (error) {
      console.log(error);
    }
  }),
  getMessages: thunk(async (actions, payload) => {
    const { conversationId, userId, offset, limit } = payload;
    try {
      const { data } = await api.get(
        urlParamReplacer(CHAT.MESSAGES, conversationId) + `?offset=${offset}&limit=${limit}`
      );
      const messages = data?.messages.map((item) => {
        return {
          text: item.text,
          image: item.mediaURL,
          direction: item.sender._id === userId ? "outgoing" : "incoming",
          sender: item.sender.nickname,
          sentTime: item.created,
          profilePicture: item.sender.profilePicture,
        };
      });
      actions.setMessages({ messages, offset });
    } catch (error) {
      actions.setMessages({ messages: [], offset });
      console.log(error);
    }
  }),
};
export default chatModel;
