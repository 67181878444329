import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api from "../../api/axios";
import { urlParamReplacer, VOTES } from "./apiRoutes";

const votePlayerModel = {
  // DATA
  previousBest: null,
  hasVoted: null,
  players: null,
  selectDayOptions: [],
  vote: null,

  // ACTIONS
  setPreviousBest: action((state, previousBest) => {
    state.previousBest = previousBest;
  }),
  setPlayers: action((state, players) => {
    state.players = players;
  }),
  setHasVoted: action((state, vote) => {
    state.previousBest.hasVoted = vote;
  }),
  setVote: action((state, vote) => {
    state.vote = vote;
  }),

  // THUNKS
  getPlayers: thunk(async (actions, payload) => {
    const { teamId } = payload;
    const competitionId = process.env.REACT_APP_COMPETITION_ID;
    try {
      const { data } = await api.get(urlParamReplacer(VOTES.PLAYERS, competitionId, teamId));
      actions.setPlayers({
        userVote: data.userVot,
        players: data?.players
          ?.map((item) => {
            return {
              ...item,
              label: item.playerName.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
                letter.toUpperCase()
              ),
              value: item._id,
            };
          })
          .sort((a, b) => (a.label !== b.label ? (a.label < b.label ? -1 : 1) : 0)),
      });
    } catch (error) {
      console.log(error);
    }
  }),
  sendVote: thunk(async (actions, payload) => {
    await api.post(VOTES.VOTING, payload);
  }),
  getVote: thunk(async (actions) => {
    const { data } = await api.get(VOTES.GET_VOTE);
    actions.setVote(data);
  }),
};
export default votePlayerModel;
