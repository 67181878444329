/**
 * Handles the expired JWT token
 * @param {function} api
 * @param {error} error
 * @param {function} getRefreshedToken
 *
 */
const handleJWTExpired = async (api, error, getRefreshedToken) => {
  // jwt expired
  const originalRequests = error.config;
  const accessToken = await getRefreshedToken();
  if (accessToken) {
    originalRequests._retry = true;

    // Setting the last requests header with new access token
    originalRequests.headers["Authorization"] = `Bearer ${accessToken}`;
    api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    // Remake the last 401 requests
    return api(originalRequests);
  }
};

export default handleJWTExpired;
