// FUNCTIONS
export const urlParamReplacer = (url, ...params) => {
  const anyWordStartingWithColonRegex = /:\w+/;
  params.forEach((param) => {
    url = url.replace(anyWordStartingWithColonRegex, param);
  });
  return url;
};

// ROUTES
export const AUTH = {
  AUTHENTICATION: "/authentication/signin/sportworld",
  REFRESH_TOKEN: "/authentication/refresh-token",
  FORGOT_PASSWORD: "/authentication/forgot-password",
  PROFILE_PICTURE: "/users/profile-picture",
  REGISTER: "/users/sportworld/register",
  POINTS_AND_RANK: "/challenge-rankings/me",
};

export const GAME = {
  TEAMS: "/teams/:competitionId",
};

export const MATCHES = {
  CHALLENGES: `/challenges/external-official-challenge?externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
  MATCHES: `/challenges/:id/matches/sportworld?type=0&externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
  PREVIOUS_MATCHES: `/challenges/:id/matches/sportworld?type=2&externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
  PLACED_MATCHES: `/challenges/:id/matches/sportworld?type=1&externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
};

export const OUTRIGHTS = {
  ALL: "/challenges/:id/outrights",
  USER_BETS: "/user-bets/outright",
};

export const ODDS = {
  ODDS: "/odds/:gameId/:challengeId",
  USER_BETS: "/user-bets",
  USER_BETS_UPDATE: "/user-bets/:challengeId/:gameId",
};

export const LEADERBOARDS = {
  SELECT_OPTIONS: `/challenge-rankings/external-championship-intervals?externalType=${process.env.REACT_APP_PROJECT_TYPE}`,
  LEADERBOARDS_USERS: "/challenge-rankings",
};

export const VOTES = {
  PLAYERS: "/voting/players/:competitionId/:teamId",
  VOTING: "/voting/add", // Body: "playerId" si "gameId"
  GET_VOTE: "/voting/my-vot",
};

export const CHAT = {
  CHALLENGE: "/challenges/:challengeId",
  GROUPS: "/group/team/:teamId",
  MESSAGES: "/messages/:conversationId",
  CONVERSATIONS: "/conversations/:conversationId",
  MEDIA: "/messages/:conversationId/media",
  PROFANITY: "/settings?name=profanityWords",
};
