import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useStoreState } from "easy-peasy";

import styles from "./Navigation.module.scss";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import classNames from "classnames";

const Navigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { items } = useStoreState((store) => store.navigation);

  return (
    <ul className={classNames(styles.navigation)}>
      {items.map((item, i) => (
        <Link to={`${item.url}${location.search}`} key={i}>
          <li
            className={classNames(styles.navigation_item, {
              [styles.active]: location.pathname === item.url,
            })}
          >
            <div className={styles.image_container}>
              <ReactSVG src={item.image} />
            </div>
            <p>{t(`menu.${item.key}`)}</p>
          </li>
        </Link>
      ))}
    </ul>
  );
};

export default Navigation;
