import React, { useEffect } from "react";
import "./Chat.scss";
import { ConversationsList } from "components";
import swLogo from "assets/sw-logo.png";
import { useTranslation } from "react-i18next";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useLocation, useNavigate } from "react-router-dom";

const Chat = () => {
  const { t } = useTranslation();
  const { chalangeId } = useStoreState((store) => store.game);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { favoriteTeamChatId, globalChatId } = useStoreState((store) => store.chat);

  const { profileImage, favoriteTeamSW } = useStoreState((store) => store.user);
  const { getFavoriteTeamChat, getGlobalChatId, getProfanityWords, getLastConversations } =
    useStoreActions((actions) => actions.chat);

  useEffect(() => {
    if (favoriteTeamChatId) {
      getLastConversations({ conversationId: favoriteTeamChatId });
    }
    if (globalChatId) {
      getLastConversations({ conversationId: globalChatId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favoriteTeamChatId, globalChatId]);

  useEffect(() => {
    getFavoriteTeamChat(favoriteTeamSW);
    getProfanityWords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (chalangeId) {
      getGlobalChatId({ challengeId: chalangeId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chalangeId]);

  return (
    <div className="chat container">
      <div className="scroll">
      </div>
      <div style={{ position: "relative" }}>
        <p className="small_title">{t("chat.latest")}</p>
        <ConversationsList />
      </div>
    </div>
  );
};

export default Chat;
