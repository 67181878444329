import matchIcon from "assets/match-icon.svg";
import leaderboardsIcon from "assets/icons/leaderboard.svg";
import prizesIcon from "../../assets/icons/prizes-icon.svg";
import chatIcon from "../../assets/icons/chat-2.svg";

const navigationModel = {
  items: [
    {
      key: "matches",
      url: "/",
      image: matchIcon,
    },
    {
      key: "leaderboards",
      url: "/leaderboards",
      image: leaderboardsIcon,
    },
    {
      key: "prizes",
      url: "/prizes",
      image: prizesIcon,
    },
    {
      key: "chat",
      url: "/chat",
      image: chatIcon,
    },
  ],
};

export default navigationModel;
